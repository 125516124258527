import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

class TVShowsPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <section className="top">
          <Helmet title="TV Shows" />
          <h1 className="hero-title">TV Shows</h1>
        </section>
        <section>
          <h2>Coming Soon</h2>
        </section>
      </Layout>
    )
  }
}

export default TVShowsPage
